<!-- 出库列表 -->

<template>
  <div class="leaveList">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="orderValue" placeholder="搜索出库单号" suffix-icon="el-icon-search" clearable @change="onSearch"></el-input>
        <el-input v-model="marketNameValue" placeholder="搜索商超名称" suffix-icon="el-icon-search" clearable @change="onSearch"></el-input>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

      <!-- 表格 -->
      <div class=" globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="orderNum" label="出库单号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="purchaseNum" label="采购单号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="outType" label="来源">
            <template slot-scope="scope">
              {{ scope.row.outType == '1' ? '发货出库' : ' ' }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdUserName" label="出库人员"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdTime" label="出库时间"></el-table-column>
          <el-table-column show-overflow-tooltip label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button plain type="primary" @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      orderValue: "", // 搜索出库单号
      marketNameValue: "", // 搜索商超名称
      dataValue: "", // 选择时间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  created () {
    this.getInventoryOutOrderList();
  },
  methods: {
    // 获取列表
    getInventoryOutOrderList () {
      this.loading = true;
      let params = {
        orderNum: this.orderValue || null, // 搜索出库单号
        shopName: this.marketNameValue || null, // 搜索商超名称
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间（开始时间-结束时间）
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }

      this.$axios.get(this.$api.getInventoryOutOrderList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 查看
    handleCheck (row) {
      this.$router.push({ path: '/stock/leaveManagement/leaveDetail', query: { orderNum: row.orderNum } })
    },
    // 清空
    clearData () {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 时间搜索
    handleSelectData (value) {
      this.clearData();
      this.dataValue = value;
      this.getInventoryOutOrderList();
    },
    // 搜索
    onSearch () {
      this.clearData();
      this.getInventoryOutOrderList();
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryOutOrderList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryOutOrderList();
    },
  },
};
</script>

<style scoped lang="scss">
.leaveList {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
